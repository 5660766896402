import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;

  business_name:boolean,
  user_name:boolean,
  main_contact: boolean,
  position: boolean,
  business_phone_num:boolean,
  main_contact_phone_num:boolean,
  business_email:boolean,
  website:boolean,
  business_address:boolean,
  address2:boolean,
  business_city:boolean,
  business_state:boolean,
  business_zip:boolean,
  password:boolean,
  re_enter_password:boolean,
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;

  business_name:string,
  user_name:string,
  main_contact: string,
  position: string,
  business_phone_num:string,
  main_contact_phone_num:string,
  business_email:string,
  website:string,
  business_address:string,
  address2:string,
  business_city:string,
  business_state:string,
  business_zip:string,
  password:string,
  re_enter_password:string,
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}

interface TimeFields {
  opensAt: string;
  closesAt: string;
  closed: boolean;
}

interface WeekTimes {
  [key: string]: TimeFields;
}

export type Weekday = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

interface EstablishmentOffer {
  id: number;
  name: string;
  selection_options: string | string[];
  value_type:string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  agree_terms: boolean;
  agree_Owner:boolean;

  showPassword: boolean;
  showConfirmPassword:boolean;
  categoryData:string[];
  subCategoryData:string[];
  establishmentOffer2Data:string[];
  establishmentOffer1Data:EstablishmentOffer[];

  business_name:string,
  user_name:string,
  main_contact: string,
  position: string,
  business_phone_num:string,
  main_contact_phone_num:string,
  business_email:string,
  website:string,
  business_address:string,
  address2:string,
  business_city:string,
  business_state:string,
  business_zip:string,
  password:string,
  re_enter_password:string,
  type:string,
  invalidZip:string;
  invalidUserName: string;
  invalidEmail:string;
  invalidBusinessName:string;
  helpUsId:number;
  successSnackbar:boolean;
  successSnackbar2:boolean;

  selectedCategory:string;
  subSelectedCategories: string[];
  otherCategory: string;
  otherOffer:string;
  establishment_offer2:string[];
  establishment_offer1:{ [key: string]: [string, string | string[]]; };
  briefDescription:string;

  openPopup:boolean;
  openPopup2:boolean;
  openingDays: { [key in Weekday]: boolean };
  openingDays2: { [key in Weekday]: boolean };
  setTimes:WeekTimes;
  setTimes2:WeekTimes;
  displayedTimes:WeekTimes;
  displayedTimes2:WeekTimes;
  popupSubmit:boolean;
  popupSubmit2:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      agree_terms: false,
      agree_Owner: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,

      showPassword:false,
      showConfirmPassword:false,
      categoryData:[],
      subCategoryData:[],
      establishmentOffer2Data:[],
      establishmentOffer1Data:[],

      business_name:"",
      user_name:"",
      main_contact: "",
      position: "",
      business_phone_num:"",
      main_contact_phone_num:"",
      business_email:"",
      website:"",
      business_address:"",
      address2:"",
      business_city:"",
      business_state:"",
      business_zip:"",
      password:"",
      re_enter_password:"",
      type: "",
      invalidZip:"",
      invalidUserName: "",
      invalidEmail:"",
      invalidBusinessName:"",
      helpUsId:0,
      successSnackbar:false,
      successSnackbar2:false,

      selectedCategory:"",
      subSelectedCategories:[],
      otherCategory: "",
      otherOffer:"",
      establishment_offer2:[],
      establishment_offer1:{},
      briefDescription:"",
    openingDays: {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    openingDays2: {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    openPopup:false,
    openPopup2:false,
    setTimes: {
      Sunday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Monday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Tuesday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Wednesday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Thursday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Friday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Saturday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
    },
    setTimes2: {
      Sunday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Monday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Tuesday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Wednesday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Thursday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Friday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
      Saturday: { opensAt: 'Closed', closesAt: 'Closed', closed: false },
    },
    displayedTimes: { 
      Sunday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Monday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Tuesday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Wednesday: { opensAt: 'Closed', closesAt: 'Closed', closed: true },
      Thursday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true  },
      Friday: { opensAt: 'Closed', closesAt: 'Closed', closed: true  },
      Saturday: { opensAt: 'Closed', closesAt: 'Closed', closed: true  },
    },
    displayedTimes2: { 
      Sunday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Monday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Tuesday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true },
      Wednesday: { opensAt: 'Closed', closesAt: 'Closed', closed: true },
      Thursday: { opensAt: 'Closed', closesAt: 'Closed',  closed: true  },
      Friday: { opensAt: 'Closed', closesAt: 'Closed', closed: true  },
      Saturday: { opensAt: 'Closed', closesAt: 'Closed', closed: true  },
    },
    popupSubmit:false,
    popupSubmit2:false,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId == this.addUserApiCallId ) {
        const { data, sub_catgory_name, error, status, errors } = responseJson;  

        if (data?.id) {
          window.localStorage.setItem("accountID", data.id);
          storage.set("businessSignupInfo", "");
          this.setState({ successSnackbar: true }, () => {
            setTimeout(() => {
              this.goTohelpUsSignUp();
            }, 3000);
          });
        } else if (sub_catgory_name) {
          this.setState({ successSnackbar2: true }, () => {
            setTimeout(() => {
              this.goToLogin();
            }, 3000);
          });
        } else if (error) {
          const { error } = responseJson;
            this.setState({
              invalidBusinessName: error?.business_name,
              invalidUserName: error?.user_name,
              invalidEmail: error?.email,
              invalidZip: error?.country_code,
            });
        } else if (status === 422 ) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (errors) {
          toast.error(errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getUserListApiCallId) {
          let filterData = responseJson.data.map((item: ResponseJson) => {
            return {
              userId: item.id,
              firstName: item.attributes.first_name,
              lastName: item.attributes.last_name,
              phoneNumber: item.attributes.phone_number,
              email: item.attributes.email,
              organization: item.attributes.organization,
              teamName: item.attributes.team_name,
              userType: item.attributes.i_am,
              rating: item.attributes.stars_rating.toString(),
              gender: item.attributes.gender,
              address: item.attributes.address,
              country: item.attributes.country,
              state: item.attributes.state,
              city: item.attributes.city,
              file: item.attributes.file.file_name,
            };
          });
          this.setState({ filterData }, () => {
            this.onChangeHandler(0);
          });
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!");
          this.setState({ openDialogName: "" });
          this.onCancel();
          this.getUserList();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!");
          this.setState({ openDialogName: "", isChange: false });
          this.onCancel();
          this.getUserList();
        }
      }else if(apiRequestCallId == this.getUserListApiCallId ) {
        this.setState({categoryData:responseJson.categories})
        this.setState({subCategoryData:responseJson.sub_categories})
        this.setState({establishmentOffer2Data:responseJson.establishment_offer2})
        this.setState({
          establishmentOffer1Data: [
            ...responseJson.establishment_offer1,
            {
              id: 'other',
              name: 'Other',
              value_type: 'String',
              selection_options: '',
            },
          ],
        });
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    storage.get("businessSignupInfo").then(res =>{
      if (res) {
        let storageData = JSON.parse(res);
        this.setState(prevState => ({
          ...prevState,
          ...storageData
        }))
      }
    });
    this.getUserList();
  }


  handleClickOpenPopup = () => {
    this.setState({ openPopup: true });
  };

  handleClosePopup = () => {
    this.setState({openPopup: false});
  };

  handleClickOpenPopup2 = () => {
    this.setState({ openPopup2: true });
  };

  handleClosePopup2 = () => {
    this.setState({openPopup2: false});
  };

  formatTime = (setTimes: string) => {
    if (setTimes === 'Closed') return 'Closed';
    const [hours, minutes] = setTimes.split(':');
    const period = parseInt(hours) >= 12 ? 'pm' : 'am';
    const formattedHours = ((parseInt(hours) % 12) || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
};

handleSubmitPopup = () => {
  const { setTimes, displayedTimes, openingDays } = this.state;
  const updatedTimes: WeekTimes = {};

  (Object.keys(setTimes) as Weekday[]).forEach(day => {
    const dayTimes = setTimes[day];
    const isClosed = !openingDays[day];
    const formattedOpensAt = isClosed ? 'Closed' : this.formatTime(dayTimes.opensAt);
    const formattedClosesAt = isClosed ? 'Closed' : this.formatTime(dayTimes.closesAt);
    updatedTimes[day] = {
      opensAt: formattedOpensAt,
      closesAt: formattedClosesAt,
      closed: isClosed,
    };
  });

  this.setState({
    displayedTimes: {
      ...displayedTimes,
      ...updatedTimes
    },
    popupSubmit:true,
    openPopup: false
  });
};


handleSubmitPopup2 = () => {
  const { setTimes2, displayedTimes2, openingDays2 } = this.state;
  const updatedTimes: WeekTimes = {};

  (Object.keys(setTimes2) as Weekday[]).forEach(day => {
    const dayTimes = setTimes2[day];
    const isClosed = !openingDays2[day];
    const formattedOpensAt = isClosed ? 'Closed' : this.formatTime(dayTimes.opensAt);
    const formattedClosesAt = isClosed ? 'Closed' : this.formatTime(dayTimes.closesAt);
    updatedTimes[day] = {
      opensAt: formattedOpensAt,
      closesAt: formattedClosesAt,
      closed: isClosed,
    };
  });

  this.setState({
    displayedTimes2: {
      ...displayedTimes2,
      ...updatedTimes
    },
    popupSubmit2:true,
    openPopup2: false
  });
};

handleCheckboxChange = (day: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState(prevState => ({
    openingDays: {
      ...prevState.openingDays,
      [day]: event.target.checked
    }
  }));
};

handleCheckboxChange2 = (day: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState(prevState => ({
    openingDays2: {
      ...prevState.openingDays2,
      [day]: event.target.checked
    }
  }));
};

handleTimeChange = (day : Weekday, field: keyof TimeFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    setTimes: {
          ...this.state.setTimes,
          [day]: { ...this.state.setTimes[day], [field]: event.target.value }
      }
  });
};

handleTimeChange2 = (day : Weekday, field: keyof TimeFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    setTimes2: {
          ...this.state.setTimes2,
          [day]: { ...this.state.setTimes2[day], [field]: event.target.value }
      }
  });
};

prepareScheduleData = () => {
  const { displayedTimes } = this.state;
  const scheduleData = Object.keys(displayedTimes).reduce((acc, day) => {
      const { opensAt, closesAt } = displayedTimes[day];
      acc[day] = opensAt === 'Closed' ? 'Closed' : `${opensAt} - ${closesAt}`;
      return acc;
  }, {} as { [key: string]: string });

  return {
      schedule_1: scheduleData
  };
};

prepareScheduleData2 = () => {
  const { displayedTimes2 } = this.state;
  const scheduleData = Object.keys(displayedTimes2).reduce((acc, day) => {
      const { opensAt, closesAt } = displayedTimes2[day];
      acc[day] = opensAt === 'Closed' ? 'Closed' : `${opensAt} - ${closesAt}`;
      return acc;
  }, {} as { [key: string]: string });

  return {
      schedule_2: scheduleData
  };
};

  handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({selectedCategory: event.target.value});
  };

  handleSubCategoryChange = (value: string) => {

    let updatedCategories: string[] = [...this.state.subSelectedCategories];
    
    if (updatedCategories.includes(value)) {
        updatedCategories = updatedCategories.filter(category => category !== value);
    } else {
        updatedCategories.push(value);
    }

    this.setState({ subSelectedCategories: updatedCategories });
};

  handleOtherCategoryChange = (text: string) => {
    this.setState({ otherCategory: text });
  };

  handleOtherCategoryChange2 = (text: string) => {
    this.setState({ otherOffer: text });
  };

  handleDescriptionChange = (text: string) => {
    this.setState({briefDescription: text});
  };

  setSignUpFieldsState = (key: string, value: string | boolean | File) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  sendNavigationMessage(target: string) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), target);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  goToLogin() {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  goToTermsAndCondition = () => {
    this.sendNavigationMessage("TermsConditions");
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value }, () => {
        storage.set("businessSignupInfo", JSON.stringify(this.state));
    });
  };

  onFormControlChange = (name: string) => {
    this.setState((prevState) => {
        const isSelected = prevState.establishment_offer2.includes(name);
        let updatedOffers = [...prevState.establishment_offer2];

        if (isSelected) {
            updatedOffers = updatedOffers.filter(category => category !== name);
        } else {
            updatedOffers.push(name);
        }

        return { establishment_offer2: updatedOffers };
    });
};



  onFormControlChange3 = (name: string, value: [string, string | string[]]) => {
    this.setState(prevState => ({
      establishment_offer1: {
        ...prevState.establishment_offer1,
        [name]: value
      }
    }));
  }

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  };

  onFileChange = (file: File | null) => {
    this.setState({ file });
    if (this.state.openDialogName === "Edit") {
      this.setState({ isChange: true });
    }
  };

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit",
    });
  };

  onCancel = () => {
    this.setState({
      openDialogName: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: "",
    });
  };

  getUserList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  prepareDataForAPI = () => {
    const { establishment_offer1 } = this.state;
    return Object.keys(establishment_offer1).map((key) => {
      const [radioValue, dropdownValue] = establishment_offer1[key];
      if (key === "Other") {
        return null;
      }
      if (radioValue !== "") {
        return {
          name: key,
          selected_options: dropdownValue,
        };
      }
      return null;
    }).filter((item) => item !== null);
  }
  

  helpUsSignUp = () =>{
    const dataToSend = this.prepareDataForAPI();
    const schedule_1 = this.prepareScheduleData();
    const schedule_2 = this.prepareScheduleData2();
    this.setState({ loading: true });
    const accountId = window.localStorage.getItem("accountID");
    const helpUsData = {
      help_us_help_you: {
        catgory_name: this.state.selectedCategory,
        sub_catgory_name: [...this.state.subSelectedCategories, this.state.otherCategory],
        description: this.state.briefDescription,
        account_id: accountId,
        establishment_offer1: dataToSend,
        establishment_offer2: [...this.state.establishment_offer2, this.state.otherOffer],
        ...schedule_1,
        ...schedule_2
      }
    };

    const header = {
      "Content-Type": configJSON.getUserListApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(helpUsData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFormAPiEndPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
    };

    const formData = new FormData();

    formData.append("business_name", this.state.business_name);
    formData.append("user_name", this.state.user_name);
    formData.append("contact_name", this.state.main_contact);
    formData.append("position", this.state.position);
    formData.append("business_contact_no", this.state.business_phone_num.toString());
    formData.append("main_contact_cell_no", this.state.main_contact_phone_num.toString());
    formData.append("email", this.state.business_email);
    formData.append("website", this.state.website);
    formData.append("address", this.state.business_address);
    formData.append("address2", this.state.address2);
    formData.append("city", this.state.business_city);
    formData.append("state_name", this.state.business_state);
    formData.append("country_code", this.state.business_zip.toString());
    formData.append("password_digest", this.state.password);
    formData.append("confirm_password", this.state.re_enter_password);
    formData.append("type", "business_account");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClickShowPass = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }
  handleMouseDownPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  }
  handleMouseDownConfirmPassword = () => {
    this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };

  goTohelpUsSignUp = () => {

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessSignupForm1"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    if (this.state.isChange) {
      formData.append("form[file]", this.state.file as Blob);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
