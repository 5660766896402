import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, Theme } from "@material-ui/core/styles";
import "@mui/styles";
import DatePicker from "react-datepicker";
import {backgroundImg, 
  barIcon,
  restaurantIcon,
  wineryIcon,
  breweryIcon,
  badge1,
  badge2,
  badge3,
  friendsImg,
  userImg,
  eventPicImg,
  UploadImgIcon,
}  from "./assets";
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Gallery from "./Gallery.web";
import "./CustomisableUserProfiles.css";
import { Alert, Snackbar } from "@mui/material";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    const icons = [barIcon, wineryIcon, breweryIcon, restaurantIcon]; 

    const likedByAccountToShow = this.state.likedAccounts.slice(0, 4);
    const likedPlaceholders = Array.from({ length: 4 - likedByAccountToShow.length }); 

    const renderClassName = (value: string, defaultValue: string) => {
      return this.state.editMode ? value : defaultValue;
    };

    const renderErrorMessage = (errorMessage : string) => {
      return errorMessage ? <div className="invalid-error-patron">{errorMessage}</div> : null;
    }

    const renderInfo = (value: string, defaultValue: string) => (
      <Typography style={webStyle.profileInfo} gutterBottom>
        {value ? value : defaultValue}
      </Typography>
    );    

    return (
      <ThemeProvider theme={theme}>
        <Box style={{marginTop:"5.625vw", overflowX:"hidden"}}>
        <Header {...this.props}/>
        <Box style={webStyle.backgroundImg}>
          <Box style={webStyle.mainContainer}>
              <Grid container spacing={2} justifyContent="center" alignItems='center'>
                <Grid item xs={12}>
                  <Paper>
                    <Box style={webStyle.userprofileContainer}>
                      <Box sx={webStyle.userInfo1}>
                        {this.state.editMode ? (
                          <>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              size="small"
                              placeholder="Name"
                              className="formInputProfile"
                              value={this.state.name}
                              style={webStyle.profileInfo}
                              data-test-id={"txtInputName"}
                              name="name"
                              onChange={(e) =>
                                this.handleInputChange(e.target.name, e.target.value)}
                            />
                              <TextField
                              variant="outlined"
                              margin="dense"
                              size="small"
                              placeholder="userName"
                              className="formInputProfile"
                              value={this.state.userName}
                              style={webStyle.profileInfo}
                              data-test-id={"txtInputUserName"}
                              name="userName"
                              onChange={(e) =>
                                this.handleInputChange(e.target.name, e.target.value)}
                            />
                             {renderErrorMessage(this.state.invalidUserName)}
                          </>
                        ) : (
                          <>
                              {renderInfo(this.state.name, "Name")}
                              {renderInfo(this.state.userName, "User Name")}
                          </>
                        )}
                      </Box>
                      <Box sx={webStyle.userInfo2}>
                        {this.state.editMode ? (
                          <>
                            <div className="container">
                            <img src={this.state.imageUrl as string} alt="Image" style={webStyle.imgStyle} />
                            <Button className="buttonStyle">
                              <input type="file" data-test-id="uploadImageProfile" id="uploadPicture" style={{ display: 'none' }} onChange={this.handleUploadImgChange} />
                              <label htmlFor="uploadPicture">
                              <img style={{ cursor: 'pointer', width:"1.389vw" }} src={UploadImgIcon}></img>
                              </label>
                            </Button>
                            </div>
                            <Typography noWrap style={{ ...webStyle.profileInfo, marginTop: "1.389vw" }}>Joined in {this.state.joined_in}</Typography>
                          </>
                        ) : (
                          <> 
                            <img src={this.state.imageUrl as string} alt="Image" style={webStyle.imgStyle} />
                            <Typography noWrap style={{ ...webStyle.profileInfo, marginTop: "1.389vw" }}>Joined in {this.state.joined_in}</Typography>
                          </>
                        )}
                      </Box>
                      <Box sx={webStyle.userInfo3}>
                        {this.state.editMode ? (
                          <>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              size="small"
                              placeholder="Email"
                              className="formInputProfile"
                              value={this.state.user_email}
                              style={webStyle.profileInfo}
                              data-test-id={"txtInputEmail"}
                              name="user_email"
                              onChange={(e) =>
                                this.handleInputChange(e.target.name, e.target.value)}
                            />
                            {renderErrorMessage(this.state.invalidEmail)}
                            <TextField
                              variant="outlined"
                              margin="dense"
                              size="small"
                              placeholder="City, State"
                              className="formInputProfile"
                              value={this.state.country_code}
                              data-test-id={"txtInputCode"}
                              name="country_code"
                              style={{ ...webStyle.profileInfo, marginTop: "1.389vw" }}
                              onChange={(e) =>
                                this.handleInputChange(e.target.name, e.target.value)}
                            />
                            {renderErrorMessage(this.state.invalidCountryCode)}
                          </>
                        ) : (
                          <>
                             {renderInfo(this.state.user_email, "Email")}
                            <Typography style={webStyle.profileInfo}>{this.state.city_name ? this.state.city_name + ", " + this.state.state_name : "City, State"}</Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={webStyle.btnContainer}>
                    <Button
                      data-test-id={"editButton"}
                      className="edit-all-btn"
                      onClick={this.state.editMode ? this.updateCustomizableProfile : this.handleEditClick }
                    >
                      {this.state.editMode ? "Submit" : "Edit All"}
                    </Button>
                    <Snackbar open={this.state.dataEditedPopup} autoHideDuration={1000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                      <Alert
                        severity="success"
                        variant="filled"
                        className="alert-submission"
                        sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                      >
                        Data Edited Successfully!
                      </Alert>
                    </Snackbar>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Paper className={renderClassName("itemStyleTopThree disabled", "itemStyleTopThree")} >
                    <Typography className="topThreeCheckinsHeading" style={webStyle.itemHeading}>Top 3 Check-ins</Typography>
                    {
                      this.state.top_three_check_ins?.slice(0,3).map((checkin) => (
                        <Typography className="topThreeCheckinsTypo" gutterBottom key={checkin.business_name}>{checkin.business_name}</Typography>
                      ))
                    }
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={renderClassName("itemStyleYourTops disabled", "itemStyleYourTops")} >
                    <Typography className="yourTopsHeading" style={webStyle.itemHeading}>Your Tops</Typography>
                    {this.state.your_tops.map((tops, index) => (
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "1px" }} key={index}>
                        <img src={icons[index]} alt={`Icon ${index}`} key={index} className="tops-icon-style"/>
                        <Typography className="yourTopsTypo" key={index}>
                          {tops}
                        </Typography>
                      </div>
                    ))}
                  </Paper>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <Paper className={renderClassName("itemStyleBadges disabled", "itemStyleBadges")} >
                        <Box className="itemHeadingSeeAll">
                          <Typography style={webStyle.itemHeading}>Hopper Badges</Typography>
                          <Typography className="see-all-typo">See all</Typography>
                          </Box> 
                            <Box style={{display:"flex", justifyContent:"space-between", marginTop:"1.944vw"}}>
                              <img className="imgBadges" src={badge1}></img>
                              <img className="imgBadges" src={badge2}></img>
                              <img className="imgBadges" src={badge3}></img>
                        </Box>           
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                    <Paper className={renderClassName("itemStyleCheck disabled", "itemStyleCheck")}>
                          <Box>
                            <Typography className="totalCheckHeading">TOTAL</Typography>
                            <Typography className="totalCheckHeading">CHECK-INS</Typography>
                          </Box>
                          <Typography className="totalCheckDes">{this.state.totalCheckIns}</Typography>
                        </Paper>
                    </Grid>
                  </Grid>
                </Grid>   
                </Grid>
                <Grid item xs={6}>
                  <Paper className="itemsStyleImportantFeature">
                    <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.667vw' }}>
                      <Typography style={webStyle.itemHeading}>Important Categories</Typography>
                    </Box>
                    <Grid container className="icongridStyleCustomisable" >
                      <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: "2.28vw" }}>
                        {this.state.important_feature.map((feature) => (
                          <Box key={feature.name} className={feature.active ? "icon-container-business" : "icon-container-business opacity-inactive"} onClick={this.state.editMode ? () => this.handleIconClick(feature.name) : undefined} >
                            <img
                              src={feature.icon.url}
                              alt={feature.name}
                              className="icon-img-customisable"
                              style={{ display: 'block', margin: '0 auto' }}
                            />
                            <span className="fitericon-span">{feature.name}</span>
                          </Box>
                        ))}
                      </div>
                    </Grid>
                  </Paper>
                </Grid>
                <Gallery {...this.props} editModeGallery={this.state.editMode} image_library={this.state.image_library} />
                <Grid item xs={6} className="itemLibraryGridContainer">
                <Paper className={renderClassName("itemLibraryPaper disabled", "itemLibraryPaper")} >
                    <Box className="itemHeadingSeeAll">
                      <Typography style={webStyle.itemHeading}>Friends</Typography>
                      <Typography className="see-all-typo">See all</Typography>
                    </Box>

                    <Box className="itemGridBox" sx={{ flexGrow: 1 }}>
                        {Array(6).fill(null).map((_, index) => (
                            <Paper className="friendsContainer" key={index}>
                              <img className="friend-img" src={friendsImg} alt={`Friend ${index + 1}`} />
                              <Typography noWrap className="friend-name">Name of friend</Typography>
                            </Paper>
                        ))}
                    </Box>
                  </Paper>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid container item >
                    <Grid item xs={12} >
                    <Paper className={renderClassName("itemStyleLiked disabled", "itemStyleLiked")}>
                      <Box className="itemHeadingSeeAll" style={{marginBottom:"0.486vw"}}>
                        <Typography style={webStyle.itemHeading}>Liked Bars</Typography>
                        <Typography className="see-all-typo">See all</Typography>
                      </Box>
                      <Box className="itemGridBox" sx={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                          {likedByAccountToShow.map((account, index) => (
                            <Paper className="likedBarContainer" style={{ flex: '1 1 calc(50% - 10px)' }} key={`account-${index}`}>
                              <img className="liked-img" src={account.avatar.url} alt={`Friend ${index + 1}`} />
                              <Typography noWrap className="bar-name">{account.business_name}</Typography>
                              <Typography noWrap className="bar-city-state">{account.city}/{account.state_name}</Typography>
                            </Paper>
                          ))}
                          {likedPlaceholders.map((_, index) => (
                            <Paper className="likedBarContainer" style={{ flex: '1 1 calc(50% - 10px)' }} key={`placeholder-${index}`}>
                              <Box style={{ width: '4.861vw', height: '4.861vw', borderRadius: "50%", objectFit: "cover", overflow: "hidden", border: "1px solid #712220" }}></Box>
                              <Typography noWrap className="bar-name">Name of Bar</Typography>
                              <Typography noWrap className="bar-city-state">City/State</Typography>
                            </Paper>
                          ))}
                        </div>
                      </Box>
                    </Paper>
                    </Grid>
                  </Grid>
                  <Grid container item >
                    <Grid item xs={12}>
                    <Paper className={renderClassName("itemStyleBadges disabled", "itemStyleBadges")} >
                      <Box className="itemHeadingSeeAll" style={{marginBottom:"0.417vw"}}>
                        <Typography style={webStyle.itemHeading}>Events</Typography>
                        <Typography className="see-all-typo">See all</Typography>
                      </Box>
                      <Box className="itemGridBoxEventsActivity" sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1}>
                        {Array(3).fill(null).map((_, index) => (
                          <Grid item xs={12} key={index}>
                            <Paper className="eventActivityContainer">
                              <img className="event-img" src={eventPicImg} alt={`Friend ${index + 1}`} />
                              <Box style={{marginLeft:"0.833vw"}}>
                                <Typography noWrap className="event-acitivity-name">Event Title</Typography>
                                <Typography noWrap className="event-acitivity-type">Event Type</Typography>
                                <Typography noWrap className="event-acitivity-date">Event Date</Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    </Paper>
                    </Grid>
                  </Grid>
                </Grid>   
              </Grid>
              <Grid item xs={6}>
                <Paper className={renderClassName("itemStyleBadges disabled", "itemStyleBadges")}>
                    <Box className="itemHeadingSeeAll">
                        <Typography style={webStyle.itemHeading}>Recent Activity</Typography>
                        <Typography className="see-all-typo">See all</Typography>
                    </Box>
                    <Box className="itemGridBoxEventsActivity" sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          {Array(6).fill(null).map((_, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper className="eventActivityContainer">
                                <img className="recent-img" src={userImg} alt={`Friend ${index + 1}`} />
                                <Box  style={{marginLeft:"0.833vw"}}>
                                  <Typography noWrap className="event-acitivity-name">Matt Milan</Typography>
                                  <Typography gutterBottom noWrap className="event-acitivity-type">Earned the Brew Mister Badge</Typography>
                                  <Typography noWrap className="event-acitivity-date">03/15/24</Typography>
                                </Box>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                  </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <NavigationMenu {...this.props}/>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backgroundImg:{
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display:"flex",
    justifyContent:'center',
  },
  mainContainer:{
    marginTop:"76px" ,
    marginBottom:"72px",
    width: "58.5%",
  },
  userprofileContainer:{
    display: 'flex',
    alignItems:'center', 
    paddingLeft:"30px",
    paddingRight:"30px",
  },
  userInfo1:{
    display:'flex',
    flexDirection:'column', 
    textAlign:"right",
    width:"37.5%",
  },
  userInfo2:{
    margin:"2.083vw 1.875vw 1.111vw 2.222vw", 
    display:"flex", 
    flexDirection:'column',
    justifyContent:"center", 
    alignItems:'center',
    width:"25%",
  },
  userInfo3:{
    display:'flex', 
    flexDirection:'column', 
    textAlign:"left",
    width:"37.5%",
  },
  imgStyle:{
    width: "7.847vw", 
    height:"7.847vw",
    borderRadius: '50%' 
  },
  btnContainer:{
    display:"flex", 
    justifyContent:"center"
  },
  imgGridLibrary: {
    width:"6.806vw",
    height:"8.11vw",
    borderRadius:"8px"
  },
  itemHeading:{
    color:"#712220",
    fontWeight:700,
    fontSize:"1.112vw",
    fontFamily: "Raleway, sans-serif",
  },
  profileInfo:{
    fontSize:'1.25vw',
    fontFamily:'Raleway',
    fontWeight:500,
    color:"#181818",
  },
  editButton:{
    backgroundColor:"#C8572C",
    fontSize:"1rem",
    textTransform: "none",
    fontFamily: 'Raleway',
  }
}
// Customizable Area End
