import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    TextField,
    Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import './EmailAccountLoginBlock.css';
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
    Touched,
    Error
} from "./EmailAccountLoginController";

export default class ForgotPassword extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    userSchema = () => {
        return Yup.object().shape({
            setResetEmail: Yup.string().email('Invalid email address').required('Email is required'),
        });
    };

    getErrorMessage = (
        touched: FormikTouched<Touched>,
        errors: FormikErrors<Error>,
        value: string
    ) => {
        return (
            touched[value as keyof Error] &&
            errors[value as keyof Error] && (
                <Typography
                    style={{ marginTop: "-6px", fontSize: "0.833vw", color: "#f94b4b", textAlign: "right" }}
                >
                    {errors[value as keyof Error]}
                </Typography>
            )
        );
    };


    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box style={{ overflowX: "hidden" }}>
                <Header {...this.props} />
                <div className="bg1">
                    {
                        this.state.forgotSuccess ?
                            <Box className="containerStyle">
                                <div className="formStyle-resetpassword-confirm">
                                    <div className="resetpassword-heading">
                                        Check your email
                                    </div>
                                    <div className="resetpassword-description" style={{ width: "19.264vw" }}>
                                        We've sent a instructions on how to reset
                                        your password to <b>{this.state.setResetEmail}</b>
                                    </div>
                                    <div className="reset-btn-wrapper">
                                        <Button
                                            className="backtologin-btn"
                                            type="submit"
                                            data-test-id={"backToLoginBtn"}
                                            onClick={() => this.goToLogin()}
                                        >
                                            Back to login
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                            :
                            <Box className="containerStyle">
                                <div className="formStyle-resetpassword">
                                    {this.state.forgotFailure && <div className="resetErrorStyle">{this.state.forgotFailure}!</div>}
                                    <div className="resetpassword-heading">
                                        Forgot password?
                                    </div>
                                    <div className="resetpassword-description">
                                        Your password will be reset by email
                                    </div>
                                    <Formik
                                        data-test-id="formik2"
                                        initialValues={{
                                            setResetEmail: this.state.setResetEmail,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={(values) => { this.forgotPassword(); }}
                                        validationSchema={this.userSchema}
                                    >
                                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        size="small"
                                                        className="textStyle"
                                                        style={{ width: "22.639vw" }}
                                                        value={this.state.setResetEmail}
                                                        type="text"
                                                        fullWidth
                                                        placeholder="Email"
                                                        data-test-id={"txtInputForgotEmail"}
                                                        onChange={(event) => {
                                                            this.setResetEmail(event.target.value);
                                                            setFieldValue("setResetEmail", event.target.value);
                                                        }}
                                                    />
                                                    {this.getErrorMessage(touched, errors, "setResetEmail")}
                                                </div>
                                                <div className="reset-btn-wrapper">
                                                    <Button
                                                        className="reset-btn"
                                                        variant="contained"
                                                        type="submit"
                                                        data-test-id={"SignupBtn"}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div className="reset-btn-wrapper">
                                                    <Button
                                                        className="backtologin-btn"
                                                        type="submit"
                                                        data-test-id={"backToLogin"}
                                                        onClick={() => this.goToLogin()}
                                                    >
                                                        Back to login
                                                    </Button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </Box>
                    }
                </div>
                <NavigationMenu {...this.props} />
            </Box>
            // Customizable Area End
        );
    }

}