import React from "react";
// Customizable Area Start
import {
    Box,
    MenuItem,
    Button,
    Menu,
    Tab,
    Tabs,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    dartIcon,
    liveMusicIcon,
    karaokeIcon,
    poolIcon,
    triviaIcon,
    patioIcon,
    partyRoomIcon,
    foodIcon,
    gamblingIcon,
    happyHourIcon,
    petIcon,
    nonAlcIcon,
    arcadeIcon,
    leagueIcon,
    gtIcon,
    UnselectedPoolIcon,
    UnselectedDartIcon,
    UnselectedLiveMusicIcon,
    UnselectedKaraokeIcon,
    UnselectedTriviaIcon,
    UnselectedPatioIcon,
    UnselectedPartyRoomIcon,
    UnselectedFoodIcon,
    UnselectedGamblingIcon,
    UnselectedHappyHourIcon,
    UnselectedPetIcon,
    UnselectedNonAlcIcon,
    UnselectedArcadeIcon,
    UnselectedLeagueIcon,
    UnselectedGtIcon,
    arrowDownIcon
} from "./assets";
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

import FilteritemsController, {
    Props,
} from "./FilteritemsController";

export default class Filteritems extends FilteritemsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    iconFilter = () => {
        return (
            <Box className="mainIconContainer">

                <Box className="iconContainer" data-test-id="dartIcon" onClick={() => this.handleIconClick('dart_board')}>
                    <img className="icon-img" src={this.state.important_feature.includes('dart_board') ? dartIcon : UnselectedDartIcon} alt="Dart" />
                </Box>

                <Box className="iconContainer" data-test-id="liveIcon" onClick={() => this.handleIconClick('live_music')}>
                    <img className="icon-img" src={this.state.important_feature.includes('live_music') ? liveMusicIcon : UnselectedLiveMusicIcon} alt="Live Music" />
                </Box>

                <Box className="iconContainer" data-test-id="KaraokeIcon" onClick={() => this.handleIconClick('karaoke')}>
                    <img className="icon-img" src={this.state.important_feature.includes('karaoke') ? karaokeIcon : UnselectedKaraokeIcon} alt="Karaoke" />
                </Box>

                <Box className="iconContainer" data-test-id="PoolIcon" onClick={() => this.handleIconClick('pool_table')}>
                    <img className="icon-img" src={this.state.important_feature.includes('pool_table') ? poolIcon : UnselectedPoolIcon} alt="Pool" />
                </Box>

                <Box className="iconContainer" data-test-id="TriviaIcon" onClick={() => this.handleIconClick('triva_night')}>
                    <img className="icon-img" src={this.state.important_feature.includes('triva_night') ? triviaIcon : UnselectedTriviaIcon} alt="Trivia" />
                </Box>

                <Box className="iconContainer" data-test-id="PatioIcon" onClick={() => this.handleIconClick('patio')}>
                    <img className="icon-img" src={this.state.important_feature.includes('patio') ? patioIcon : UnselectedPatioIcon} alt="Patio" />
                </Box>

                <Box className="iconContainer" data-test-id="PartyIcon" onClick={() => this.handleIconClick('party_room')}>
                    <img className="icon-img" src={this.state.important_feature.includes('party_room') ? partyRoomIcon : UnselectedPartyRoomIcon} alt="Party Room" />
                </Box>

                <Box className="iconContainer" data-test-id="FoodIcon" onClick={() => this.handleIconClick('food')}>
                    <img className="icon-img" src={this.state.important_feature.includes('food') ? foodIcon : UnselectedFoodIcon} alt="Food" />
                </Box>

                <Box className="iconContainer" data-test-id="GamblingIcon" onClick={() => this.handleIconClick('gambling')}>
                    <img className="icon-img" src={this.state.important_feature.includes('gambling') ? gamblingIcon : UnselectedGamblingIcon} alt="Gambling" />
                </Box>

                <Box className="iconContainer" data-test-id="HappyIcon" onClick={() => this.handleIconClick('happy_hours')}>
                    <img className="icon-img" src={this.state.important_feature.includes('happy_hours') ? happyHourIcon : UnselectedHappyHourIcon} alt="Happy Hour" />
                </Box>

                <Box className="iconContainer" data-test-id="PetIcon" onClick={() => this.handleIconClick('pet_friendly')}>
                    <img className="icon-img" src={this.state.important_feature.includes('pet_friendly') ? petIcon : UnselectedPetIcon} alt="Pet" />
                </Box>

                <Box className="iconContainer" data-test-id="NonAlcIcon" onClick={() => this.handleIconClick('na_options')}>
                    <img className="icon-img" src={this.state.important_feature.includes('na_options') ? nonAlcIcon : UnselectedNonAlcIcon} alt="Non-Alcoholic" />
                </Box>

                <Box className="iconContainer" data-test-id="ArcadeIcon" onClick={() => this.handleIconClick('arcade_games')}>
                    <img className="icon-img" src={this.state.important_feature.includes('arcade_games') ? arcadeIcon : UnselectedArcadeIcon} alt="Arcade" />
                </Box>

                <Box className="iconContainer" data-test-id="LeagueIcon" onClick={() => this.handleIconClick('league_pass')}>
                    <img className="icon-img" src={this.state.important_feature.includes('league_pass') ? leagueIcon : UnselectedLeagueIcon} alt="League" />
                </Box>

                <Box className="iconContainer" data-test-id="GoldenIcon" onClick={() => this.handleIconClick('golden_tea')}>
                    <img className="icon-img" src={this.state.important_feature.includes('golden_tea') ? gtIcon : UnselectedGtIcon} alt="GT" />
                </Box>

            </Box>
        )
    }

    render() {
        // Customizable Area Start
        const arrowIcon = <img src={arrowDownIcon} alt="Arrow Down" />;
        return (
            <ThemeProvider theme={theme}>
                {/* Customizable Area Start */}
                <Box style={{ overflowX: "hidden" }}>

                    <Box className="mainContainer">

                        {this.iconFilter()}

                        <Box className="tab-segment">
                            <Tabs
                                aria-label="icon tabs example"
                                className="tabs-segment-container"
                                value={this.state.tabValue}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "transparent"
                                    }
                                }}
                                onChange={this.handleChange}
                            >
                                <Tab className="tab-segment-all" value="all" label="All" />
                                <Tab className="tab-segment-all" value="Bar" label="Bars" />
                                <Tab className="tab-segment-all" value="Brewery" label="Breweries" />
                                <Tab className="tab-segment-all" value="Winery" label="Wineries" />
                                <Tab className="tab-segment-all" value="Restaurant" label="Restaurants" />
                            </Tabs>
                            <Box className="bar-dropdown">
                                <Button
                                    id="basic-button"
                                    data-test-id="btnModal"
                                    aria-controls={Boolean(this.state.anchorEl) ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    className="arrowBtn"
                                    aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                                    onClick={this.handleClick}
                                    endIcon={arrowIcon}
                                >
                                    Bar Type
                                </Button>
                                <Menu
                                    className="menuStyle"
                                    id="basic-menu"
                                    data-test-id="btnMenu"
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Barcade</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Beach Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Cocktail Lounge</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Dive Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Irish Pub</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>LGBTQ+</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Music Venue</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Night Club</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Pool Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Roof Top Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Sports Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Tequila Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Tiki Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Speakeasy</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Whiskey Bar</MenuItem>
                                    <MenuItem className="menuitem-list" onClick={this.handleClose}>Wine Bar</MenuItem>
                                </Menu>
                            </Box>
                        </Box>

                    </Box>

                </Box>
                {/* Customizable End Start */}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End