Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
// exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.getCustomizableProfileEndPoint = "account_block/accounts/user_profile";
exports.updateCustomizableProfileEndPoint = "account_block/accounts/";
exports.updateHelpUsHelpYouEndPoint ="bx_block_contact_us/help_us_help_yous/";
exports.getCustomizableProfileFieldsEndPoint = "bx_block_profile/profile/custom_user_profile_fields";
exports.uploadBusinessImagesEndPoint ="bx_block_upload_media/upload_images";
exports.allowCustomerImgEndPoint = "bx_block_upload_media/upload_images/allow_customer_images";
// Customizable Area End
