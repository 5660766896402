export const groupImg1 = require("../assets/group_img1.svg");
export const groupImg2 = require("../assets/group_img2.svg");
export const groupImg4 = require("../assets/group_img4.svg");
export const groupImg5 = require("../assets/group_img5.svg");
export const groupImg6 = require("../assets/group_img6.svg");
export const leftBtn = require("../assets/button_leftarrow.svg");
export const rightBtn = require("../assets/image_Right Arrows.svg");
export const groupImg8 = require("../assets/group_img8.svg");
export const groupImg10 = require("../assets/group_img10.svg");
export const groupImg11 = require("../assets/group_img11.svg");
export const barHoppingLogoLanding = require("../assets/group_BarHoppin_Logo.svg");
