import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  Tooltip
} from "@material-ui/core";
import { barhoppinLogoFooter, userProfileIcon } from "./assets";
import "./NavigationMenu.css";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let token = window.localStorage.getItem("token");
    let userName = window.localStorage.getItem("user_name");
    let { urlParam, footerData }  = this.state;
 
    return (
      <Box className="footer-2-toolBar">
        <Box className="footer-2-container">
          <Box className="footer-2-container-1">
            <Typography variant="button" className={urlParam === "/Explore"? "active-btn-footer" : "footer-2-btn"} data-test-id="btnExploreFooter" onClick={() => this.goToAdvancedSearch()}>Explore</Typography>
            <Typography className={urlParam === "/TravelBlogArticle"? "active-btn-footer" : "footer-2-btn"} data-test-id="btnTravelNavigation" onClick={() => this.goToTravelArticleBlog()}>Travel</Typography>
            <Typography className="footer-2-btn" color="inherit" >Locations</Typography>
            <Typography className={urlParam === "/AboutUs"? "active-btn-footer" : "footer-2-btn"} data-test-id="btnAbout" onClick={() => this.goToAboutUs()}>About</Typography>
            <Typography className={urlParam === "/Businesspage"? "active-btn-footer" : "footer-2-btn"} color="inherit" data-test-id="btnBusinessFooter" onClick={() => this.gotoLandingBusiness()}>For Business</Typography>
            <Typography className={urlParam === "/TermsConditionsUsers"? "active-btn-footer" : "footer-2-btn"} variant="button" color="inherit" data-test-id="btnTermsNavigation" onClick={() => this.goToTermsAndCondition()} >Terms and Conditions</Typography>
          </Box>
          <Box className="footer-2-container-2">
            <div style={{ flexGrow: 1 }}>
              <Button data-test-id="btnLanding" className="bar-hoppin-logo-btn"  onClick={() => this.goToLandingPage()}>
                <img alt="bar-hoppin-logo" src={barhoppinLogoFooter} className="barHoppinLogoStyle-footer" ></img>
              </Button>
            </div>
            <Box className="footer-2-address-typo-1">
              <Typography variant="body2" className="typographyStyleFooter1">
                {footerData.address1}
              </Typography>
              <Typography variant="body2" className="typographyStyleFooter1">
                {footerData.address2} 
              </Typography>
            </Box>
            <Box className="footer-2-address-typo-email">
              <Typography variant="body2" className="typographyStyleFooter1">
                {footerData.email}
              </Typography>
            </Box>
          </Box>
          <Box>
            {token && userName ?
              (
                <div className="logout-container">
                  <Tooltip title="Logout">
                    <Button data-test-id="btnLogoutNav" color="inherit" onClick={() => this.goToLoginPage()}><img className="user-profile-icon" alt="bar-hoppin-logo" src={userProfileIcon} ></img></Button>
                  </Tooltip>
                  <Typography className="logout-username"> {userName}</Typography>
                </div>
              )
              :
              <Button className="loginButton" data-test-id="btnLoginNavigation" color="inherit" onClick={() => this.goToLoginPage()}>Login/Create Profile</Button>
            }
          </Box>
        </Box>
        <Box className="copyright-typo">
          <Typography className="typographyStyleFooter1">{footerData.copyright}</Typography>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
