import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import axios from 'axios';
export const configJSON1 = require("../../../framework/src/config.js");
import FormData from "form-data";
import storage from "../../../framework/src/StorageProvider.web";
import { Area } from 'react-easy-crop/types';
export interface Touched {
  first_name: boolean,
  user_name:boolean,
  date_of_birth: boolean,
  country_code: boolean,
  email: boolean,
  password_digest: boolean,
  confirm_password: boolean,
}
export interface Error {
  first_name: string,
  user_name:string,
  date_of_birth: string,
  country_code: string,
  email: string,
  password_digest: string,
  confirm_password: string,
}

interface FeatureIcon {
  url: string;
  type: string;
  filename: string;
};

interface ImportantFeature{
  id: string;
  name: string;
  active:boolean;
  icon: FeatureIcon;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  user_name: string;
  first_name: string;
  date_of_birth: string;
  country_code: string;
  favorite_watering_hole: string;
  others: string;
  important_categories: string[];
  activated: boolean;
  avatar: File | null;
  agree_terms: boolean;
  imageUrl: string;
  password_digest:string;
  confirm_password: string;
  crop: { x: number; y: number };
  zoom: number;
  croppedArea: Area | null;
  croppedImage: string | null;
  invalidError:string;
  invalidUserName : string;
  invalidEmail:string;
  invalidPassword:string;
  enablePassword: boolean;
  enableReTypePassword: boolean;

  successSnackbar: boolean;
  dobValidation:boolean;
  important_feature: ImportantFeature[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  apiEmailSignUpCallId: string = "";
  getFilterIconsCallId: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      user_name: "",
      first_name: "",
      date_of_birth: "",
      country_code: "",
      favorite_watering_hole: "",
      others: "",
      important_categories: [],
      activated: true,
      avatar: null,
      agree_terms: false,
      imageUrl: "",
      password_digest:"",
      confirm_password:"",
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedArea: null,
      croppedImage: null,
      invalidError:"",
      invalidUserName:"",
      invalidEmail:"",
      invalidPassword:"",
      enablePassword:false,
      enableReTypePassword:false,

      successSnackbar:false,
      dobValidation:false,
      important_feature:[],
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (responseJson.data) {
            this.setState({ successSnackbar: true }, () => {
              setTimeout(() => {
                const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
                msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(msg);
                storage.set("signupData", "");  
              }, 3000);
            });
          } else {
            const { error } = responseJson;
            this.setState({
              invalidError: error?.country_code,
              invalidUserName: error?.user_name,
              invalidEmail: error?.email
            });
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.getFilterIconsCallId){
          this.setState({important_feature:responseJson?.important_features})
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    try {
      const res = await storage.get("signupData");
      if (res) {
        let storageData = JSON.parse(res);
        let avatar = await this.getImageAsFile(storageData.croppedImage);
        this.setState(prevState => ({
          ...prevState,
          ...storageData,
          avatar
        }))
      }
    } catch (error) {
    }
    this.getFilterIcons();
  }


  handleIconClickRegistration = (text: string) => {
    
    let updatedFeatures = [...this.state.important_categories];

    if (updatedFeatures.includes(text)) {
      updatedFeatures = updatedFeatures.filter((feature) => feature !== text);
    } else {
      updatedFeatures.push(text);
    }
    this.setState((prevState) => ({
      ...prevState,
      important_categories: updatedFeatures
    }), () => {
      storage.set("signupData", JSON.stringify({ ...this.state, important_categories: updatedFeatures }));
    });
  }


  setSignUpFields = (key: string, value: string | boolean | File) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      storage.set("signupData",  JSON.stringify(this.state));
    });
  };

  onHandleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          imageUrl: reader.result as string,
          crop: { x: 0, y: 0 },
          zoom: 1,
          croppedArea: null,
          croppedImage: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };


onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
  this.setState({ croppedArea: croppedAreaPixels });
};

onCropChange = (crop: { x: number; y: number }) => {
  this.setState({ crop });
};

onZoomChange = (zoom: number) => {
  this.setState({ zoom });
};

createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

  getCroppedImg = async (imageSrc: string, crop: Area): Promise<string> => {
  const image = await this.createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return '';

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - crop.x,
    0 - safeArea / 2 + image.height * 0.5 - crop.y
  );

   canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], 'croppedImage.jpeg', { type: 'image/jpeg' });
        this.setState({ avatar: file });
      }
    }, 'image/jpeg');

  return new Promise((resolve) => {
    const dataURL = canvas.toDataURL('image/jpeg');
    resolve(dataURL);
  });
  
}

showCroppedImage = async () => {
  const { imageUrl, croppedArea } = this.state;
  if (imageUrl && croppedArea) {
    const croppedImage = await this.getCroppedImg(imageUrl, croppedArea);
    this.setState({ croppedImage });
  }
};

  
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  sendNavigationMessages(target: string) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), target);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  goToTermsAndConditionRegistration = () => {
    this.props.navigation.navigate("TermsConditionsUsers")
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccountNative(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccountNative()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndConditionRegistration()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  getImageAsFile = (imageUrl:string) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', imageUrl, true);
      xhr.responseType = 'blob';
  
      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
          resolve(file);
        } else {
          reject(null);
        }
      };
  
      xhr.onerror = function () {
        reject(null);
      };
  
      xhr.send();
    });
  };
  
  
  handleClickShowPassword = () => {
    this.setState(prevState => ({ enablePassword: !prevState.enablePassword }));
  }
  handleMouseDownPassword = () => {
    this.setState(prevState => ({ enablePassword: !prevState.enablePassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(prevState => ({ enableReTypePassword: !prevState.enableReTypePassword }));
  }
  handleMouseDownConfirmPassword = () => {
    this.setState(prevState => ({ enableReTypePassword: !prevState.enableReTypePassword }));
  };

  handleClick = () => {
    
  const header = {
  };
  const agreeTermsValue = this.state.agree_terms ? 'true' : 'false';
  const formData = new FormData();
  formData.append('password_digest', this.state.password_digest);
  formData.append("email", this.state.email);
  formData.append('user_name', this.state.user_name);
  formData.append('first_name', this.state.first_name);
  formData.append('date_of_birth', this.state.date_of_birth);
  formData.append('country_code', this.state.country_code);
  formData.append('favorite_watering_hole', this.state.favorite_watering_hole);
  formData.append('others', this.state.others);
  formData.append('confirm_password', this.state.confirm_password);
  formData.append('important_feature', this.state.important_categories.join(', '));
  formData.append('agree_terms', agreeTermsValue);
  if (this.state.avatar) {
    formData.append('avatar', this.state.avatar);
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createAccountApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.SignUpEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeAddDetail 
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}

getFilterIcons = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getFilterIconsCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.FilterIcons}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Customizable Area End
}
