import React from "react";

// Customizable Area Start
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Button,
  Typography,
  Tooltip
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { barhoppinLogo, userProfileIcon } from "./assets";
import "./NavigationMenu.css";
// Customizable Area End

import NavigationMenuController, {
  Props,
} from "./NavigationMenuController";

export default class Header extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    let token = window.localStorage.getItem("token");
    let { urlParam }  = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
        <AppBar style={{...webStyle.appBarStyle, justifyContent:"center", boxShadow:"none"}}>
        <Toolbar style={webStyle.toolBarStyle}>
          <div style={{flexGrow:1}}>
          <Button data-test-id="btnLandingHeader" onClick={()=> this.goToLandingPage()}>
            <img alt="bar-hoppin-logo" src={barhoppinLogo} style={webStyle.barHoppinLogoStyle} ></img>
          </Button>
          </div>
          <Button className={urlParam === "/Explore"? "active-btn-header" : "header-2-btn"} data-test-id="btnExplore" style={{...webStyle.typographyStyle, ...webStyle.secondaryColorStyle}}  onClick={() => {  this.goToAdvancedSearch() }} >Explore</Button>
          <Button className={urlParam === "/TravelBlogArticle"? "active-btn-header" : "header-2-btn"} color="inherit" data-test-id="btnAboutHeader"   onClick={() => { this.goToTravelArticleBlog() }} style={webStyle.typographyStyle }>Travel</Button>
          <Button className={urlParam === "/AboutUs"? "active-btn-header" : "header-2-btn"} data-test-id="btnAbout" style={webStyle.typographyStyle}   onClick={() => { this.goToAboutUs()}}>About</Button>
          <Button className={urlParam === "/Businesspage"? "active-btn-header" : "header-2-btn"} color="inherit" data-test-id="btnLanding" style={webStyle.typographyStyle}  onClick={() => { this.gotoLandingBusiness() }}>For Business</Button>
          { token ? 
           (
            <div>
            <Tooltip title="Logout">
              <Button data-test-id="btnLogoutHeader" color="inherit" onClick={()=> this.goToLoginPage()}><img alt="bar-hoppin-logo" className="user-profile-icon" src={userProfileIcon}  ></img></Button>
            </Tooltip>
            </div>
               )
            :   
            <Button className="loginButton" data-test-id="btnLoginHeader" color="inherit"  onClick={()=> this.goToLoginPage()}>Login/Create Profile</Button>
          }
        </Toolbar>
      </AppBar>
      </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary:{
        main: "#DA9A22",
    },
  },
  typography: {
    fontFamily: 'Raleway',
    button: {
      textTransform: "none"
    },
  },
 
});
const webStyle = {
  barHoppinLogoStyle:{
    height:"2.73vw",
  },
  toolBarStyle:{
    paddingLeft: "8.90vw",
    paddingRight: "10.31vw",
    alignItems:"center",
    justifyContent:"center",
    minHeight:"5.625vw"
  },
  appBarStyle:{
    backgroundColor: "#181818",
    display:"flex",
    alignItem:"center",
  },
  typographyStyle:{
    paddingLeft:"2.65vw",
  },
  secondaryColorStyle:{
    color:"#DA9A22",
  }
};
// Customizable Area End
