import React from "react";

// Customizable Area Start
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Button,
  Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { barhoppinLogo } from "./assets";
import "./NavigationMenu.css";
// Customizable Area End

import NavigationMenuController, {
  Props,
} from "./NavigationMenuController";

export default class Footer extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {footerData} = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Toolbar className="toolBar">
        <div style={{flexGrow:1}}>
          <Button data-test-id="btnLandingFooter" onClick={()=> this.goToLandingPage()}>
             <img src={barhoppinLogo} alt="bar-hoppin-logo" className="barHoppinLogo" />
          </Button>
          </div>
          <Typography variant="body2" className="typographyStyleFooter1">
          <Typography variant="button" className="termsCondColor" data-test-id="btnTermsFooter" onClick={() => this.goToTermsAndCondition()} >Terms and Conditions</Typography>
           &nbsp;  &bull;  &nbsp; {footerData.email} &nbsp; &bull;  &nbsp;{footerData.address1}, {footerData.address2} 
          </Typography>
          <Button data-test-id="btnLoginFooter" className="loginButton" onClick={()=> this.goToLoginPage()}>
            Login/Create Profile
          </Button>
        </Toolbar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: 'Raleway',
    button: {
      textTransform: "none"
    },
  },
 
});
// Customizable Area End
