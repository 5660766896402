import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import './EmailAccountLoginBlock.css';
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
    Touched,
    Error
} from "./EmailAccountLoginController";

export default class ResetPassword extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    userSchema = () => {
        return Yup.object().shape({
            setResetPassword: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
                .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                .matches(/\d/, 'Password must contain at least one number')
                .matches(/[@#$^&*+=]/, 'Password must contain at least one special character')
                .required('Password is required'),
            setResetConfirmPassword: Yup.string()
                .oneOf([Yup.ref('setResetPassword'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        });
    };

    getErrorMessage = (
        touched: FormikTouched<Touched>,
        errors: FormikErrors<Error>,
        value: string
    ) => {
        return (
            touched[value as keyof Error] &&
            errors[value as keyof Error] && (
                <Typography
                    style={{ marginTop: "-6px", fontSize: "0.833vw", color: "#f94b4b", textAlign: "right" }}
                >
                    {errors[value as keyof Error]}
                </Typography>
            )
        );
    };


    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box style={{ overflowX: "hidden" }}>
                <Header {...this.props} />
                <div className="bg1">
                    {
                        this.state.resetSuccess ?
                            <Box className="containerStyle">
                                <div className="formStyle-resetpassword-confirm">
                                    <div className="resetpassword-heading">
                                        Password Changed
                                    </div>
                                    <div className="resetpassword-description">
                                        New password created successfully.
                                    </div>
                                    <div className="reset-btn-wrapper">
                                        <Button
                                            className="backtologin-btn"
                                            type="submit"
                                            data-test-id={"backToLoginBtn"}
                                            onClick={() => this.goToLogin()}
                                        >
                                            Back to login
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                            :
                            <Box className="containerStyle">
                                <div className="formStyle-resetpassword">
                                    {this.state.resetFailure && <div className="resetErrorStyle">{this.state.resetFailure}!</div>}
                                    <div className="resetpassword-heading">
                                        Reset password
                                    </div>
                                    <div className="resetpassword-description">
                                        Set a new password
                                    </div>
                                    <Formik
                                        data-test-id="formik2"
                                        initialValues={{
                                            setResetPassword: this.state.setResetPassword,
                                            setResetConfirmPassword: this.state.setResetConfirmPassword,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={(values) => { this.resetPassword(); }}
                                        validationSchema={this.userSchema}
                                    >
                                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        size="small"
                                                        className="textStyle"
                                                        value={this.state.setResetPassword}
                                                        type={this.state.resetPassword ? "text" : "password"}
                                                        fullWidth
                                                        placeholder="New password"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        style={{ paddingRight: 0 }}
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickShowResetPassword}
                                                                    >
                                                                        <img className="eye-icon-style" src={this.state.resetPassword ? imgPasswordVisible : imgPasswordInVisible} alt="password visibility toggle" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        data-test-id={"txtInputPassword"}
                                                        onChange={(event) => {
                                                            this.setResetPassword(event.target.value);
                                                            setFieldValue("setResetPassword", event.target.value);
                                                        }}
                                                    />
                                                    {this.getErrorMessage(touched, errors, "setResetPassword")}
                                                </div>
                                                {!!this.state.invalidUserNameField && <div className="invalid-error">{this.state.invalidUserNameField}</div>}
                                                <div>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        size="small"
                                                        value={this.state.setResetConfirmPassword}
                                                        className="textStyle"
                                                        type={this.state.resetConfirmPassword ? "text" : "password"}
                                                        fullWidth
                                                        placeholder="Confirm new password"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" >
                                                                    <IconButton
                                                                        style={{ paddingRight: 0 }}
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickShowConfirmPassword}
                                                                    >
                                                                        <img className="eye-icon-style" src={this.state.resetConfirmPassword ? imgPasswordVisible : imgPasswordInVisible} alt="password visibility toggle" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        data-test-id={"txtInputConfirmPassword"}
                                                        onChange={(event) => {
                                                            this.setResetConfirmPassword(event.target.value);
                                                            setFieldValue("setResetConfirmPassword", event.target.value);
                                                        }}
                                                    />
                                                    {this.getErrorMessage(touched, errors, "setResetConfirmPassword")}
                                                </div>
                                                {!!this.state.invalidPassword && <div className="invalid-error-password">{this.state.invalidPassword}</div>}
                                                <div className="reset-btn-wrapper">
                                                    <Button
                                                        className="reset-btn"
                                                        variant="contained"
                                                        type="submit"
                                                        data-test-id={"SignupBtn"}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </Box>
                    }
                </div>
                <NavigationMenu {...this.props} />
            </Box>
            // Customizable Area End
        );
    }

}