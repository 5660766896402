export const userProfile = require("../assets/user-profile.png");
export const barhoppinLogo = require("../assets/barhoppin-logo.svg");
export const barhoppinLogoFooter = require("../assets/image_white_logo.svg");
export const aboutUsBackgroundImg = require("../assets/aboutUsBackgroundImg.svg");
export const dinnerNightImg = require("../assets/DinnerNightimg.svg");
export const dbdnImg = require("../assets/DBDN_img.jpg");
export const searchIcon = require("../assets/search_icon.jpg");
export const btnLeft = require("../assets/button_left.png");
export const btnRight = require("../assets/button_right.png");
export const shareBtnBlog = require("../assets/image_share.png");
export const shareBtnArticle = require("../assets/image_share_article.png");
export const blogImg = require("../assets/blog_img.jpg");
export const userProfileIcon = require("../assets/image_avatar.svg");