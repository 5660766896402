export const UnselectedDartIcon = require("../assets/button_icons.svg");
export const UnselectedLiveMusicIcon = require("../assets/group_Live_Music_Icon_unselected.svg");
export const UnselectedKaraokeIcon = require("../assets/group_Karaoke_Icon_unselected.svg");
export const UnselectedPoolIcon = require("../assets/group_Pool_Icon_unselected.svg");
export const UnselectedTriviaIcon = require("../assets/group_Trivia_Icon_unselected.svg");
export const UnselectedPatioIcon = require("../assets/group_Patio_Icon_unselected.svg");
export const UnselectedPartyRoomIcon = require("../assets/group_Group_355_unselected.svg");
export const UnselectedFoodIcon = require("../assets/group_Food_Icon_unselected.svg");
export const UnselectedGamblingIcon = require("../assets/group_Gambling_Icon_unselected.svg");
export const UnselectedHappyHourIcon = require("../assets/group_Happy_Hour_Icon_unselected.svg");
export const UnselectedPetIcon = require("../assets/group_Pet_Icon_unselected.svg");
export const UnselectedNonAlcIcon = require("../assets/group_Group_357_unselected.svg");
export const UnselectedArcadeIcon = require("../assets/group_Arcade_unselected.svg");
export const UnselectedLeagueIcon = require("../assets/group_League_Pass_unselected.svg");
export const UnselectedGtIcon = require("../assets/group_GT_unselected.svg");
export const dartIcon = require("../assets/group_Darts_Icon.svg");
export const liveMusicIcon = require("../assets/group_Live_Music_Icon.svg");
export const karaokeIcon = require("../assets/group_Karaoke_Icon.svg");
export const poolIcon = require("../assets/group_Pool_Icon.svg");
export const triviaIcon = require("../assets/group_Trivia_Icon.svg");
export const patioIcon = require("../assets/group_Patio_Icon.svg");
export const partyRoomIcon = require("../assets/group_Group_355.svg");
export const foodIcon = require("../assets/group_Food_Icon.svg");
export const gamblingIcon = require("../assets/group_Gambling_Icon.svg");
export const happyHourIcon = require("../assets/group_Happy_Hour_Icon.svg");
export const petIcon = require("../assets/group_Pet_Icon.svg");
export const nonAlcIcon = require("../assets/group_Group_357.svg");
export const arcadeIcon = require("../assets/group_Arcade.svg");
export const leagueIcon = require("../assets/group_League_Pass.svg");
export const gtIcon = require("../assets/group_GT.svg");
export const arrowDownIcon = require("../assets/image_arrow_drop_down.jpg");