import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { StyleSheet } from "react-native";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { loginHerePic, appleIcon, googleIcon, facebookIcon } from "./assets";
import './EmailAccountLoginBlock.css';
export const configJSON = require("./config");
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box style={{overflowX:"hidden"}}>
        <Header {...this.props} />
        <div className="bg1">
            <Box>
              <div className="containerStyle">
                <div className="imageStyle">
                  <img src={loginHerePic} alt='Login Here'>
                  </img>
                </div>
                <div className="formStyle">
                  <form>
                    <div>
                      <TextField
                        variant="outlined"
                        margin="none"
                        size="small"
                        className="textStyle"
                        type="text"
                        fullWidth
                        placeholder="User Name"
                        data-test-id={"txtInputEmail"}
                        onChange={(e) =>
                          this.setEmail(e.target.value)}
                      />
                    </div>
                    {!!this.state.invalidUserNameField && <div className="invalid-error">{this.state.invalidUserNameField}</div>}
                    <div>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        className="textStyle"
                        type={this.state.enablePassword ? "text" : "password"}
                        fullWidth
                        placeholder="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPass}
                              >
                                {this.state.enablePassword ?  <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        data-test-id={"txtPass"}
                        onChange={(e) =>
                          this.setPassword(e.target.value)}
                      />
                    </div>
                    {!!this.state.invalidPassword && <div className="invalid-error-password">{this.state.invalidPassword}</div>}
                    <div className="forgotPasswordContainer">
                      <Button className="forgotPasswordStyle" onClick={this.goToForgotPass}>Forgot Password?</Button>
                    </div>
                    <div className="btnContainer">
                      <Button className="btnStyle mt-29 h-40">
                        <img src={googleIcon} alt='' className="btnImageForGoogle" />
                        <span className="btnText">
                          {configJSON.googleTitle}
                        </span>
                      </Button>
                      <Button className="btnStyle mt-14 h-38">
                        <img src={appleIcon} alt='' className="btnImageForApple" />
                        <span className="btnText">
                          {configJSON.appleTitle}
                        </span>
                      </Button>
                      <Button className="btnStyle mt-14 h-38">
                      <img src={facebookIcon} alt='' className="fbImageStyle" />
                        <span className="btnText  ">
                          {configJSON.facebookTitle}
                        </span>
                      </Button>
                    </div>
                    <div className="btn1Container mt-32">
                      <Button 
                       style={{
                        borderRadius: '0.556vw',
                        fontSize: '1.25vw',
                        backgroundColor: '#70211F',
                        width: '13.889vw',
                        height: '3.889vw',
                        fontWeight: 600,
                        fontFamily: '"Raleway", sans-serif',
                        color: 'white',
                        cursor: 'pointer',
                        textTransform: 'none'
                      }}
                      className="submitCreateBtn" onClick={this.handleCreateAccountClick}>
                        Create Account
                      </Button>
                      <Button
                        type="button"
                        disabled={!this.state.userName}
                        style={{
                          borderRadius: '0.556vw',
                          fontSize: '1.25vw',
                          backgroundColor: '#70211F',
                          width: '13.889vw',
                          height: '3.889vw',
                          fontWeight: 600,
                          fontFamily: '"Raleway", sans-serif',
                          color: 'white',
                          cursor: 'pointer',
                          textTransform: 'none'
                        }}
                        className="submitCreateBtn"
                        data-test-id={"SignUpBtn"}
                        onClick={() => {
                          this.handleClickLogin();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <div className="mt-24">
                        <Button 
                         style={{
                          borderRadius: '0.556vw',
                          backgroundColor: '#70211F',
                          width: '19.931vw',
                          fontWeight: 600,
                          fontSize: '1.25vw',
                          fontFamily: '"Raleway", sans-serif',
                          height: '3.889vw',
                          color: 'white',
                          cursor: 'pointer',
                          textTransform: 'none'
                        }} 
                         onClick={this.gotoBusinessSignUp}>
                          Create Business Account
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
        </div>
        <NavigationMenu {...this.props} />
      </Box>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
