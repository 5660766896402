import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    CardMedia,
    CardContent,
    Grid,
    IconButton,
    InputBase,
    Button
} from "@material-ui/core";
import Header from "./Header.web";
import NavigationMenu from "./NavigationMenu.web";
import "./NavigationMenu.css";

import TravelBlogArticleController, {
    Props,
} from "./TravelBlogArticleController";
import {  dinnerNightImg,searchIcon, blogImg, shareBtnBlog, btnLeft, btnRight, shareBtnArticle} from "./assets";
// Customizable Area End

export default class TravelBlogArticle extends TravelBlogArticleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { travelArticleData, currentIndex , selectedBlog } = this.state;
        const currentBlog = travelArticleData[currentIndex];

        return (
            <Box style={{ overflowX: "hidden" }}>
                <Header {...this.props} />   
                <Box sx={webStyle.mainWrapperBackground} style={{backgroundImage: `url('${this.state.bannerImage}')`}} >
                            <Box className={selectedBlog ?"blog-heading-container extra-spacing":"blog-heading-container"}>
                                <Typography className="blog-category">{selectedBlog ? selectedBlog?.category : currentBlog?.category}</Typography>
                                <Typography className="blog-heading">{selectedBlog? selectedBlog?.title : currentBlog?.title}</Typography>
                                <Typography className="blog-description" gutterBottom>   {!selectedBlog && currentBlog?.description?.substring(3, 111) || ' '}</Typography>
                                <Box className="blog-content">
                                    <Box className="blog-content-1">
                                        <Typography className="blog-content-font blog-author" gutterBottom>{selectedBlog ? selectedBlog?.author_name :  currentBlog?.author_name} </Typography>
                                        <Typography className="blog-content-font blog-date" gutterBottom>{selectedBlog ? selectedBlog?.date : currentBlog?.date}</Typography>
                                    </Box>
                                    <Typography className="blog-content-font blog-read-more"  data-test-id="btnArticle" gutterBottom onClick={() => this.handleReadMore(currentBlog)}> {!selectedBlog && "READ MORE"}</Typography>
                                </Box>
                                <Box className="slider-btns">
                                {!selectedBlog &&
                                    <>
                                        <Button className="prev-next-btn" onClick={this.handlePrev}><img src={btnLeft}></img></Button>
                                        <Button className="prev-next-btn" onClick={this.handleNext}><img src={btnRight}></img></Button>
                                    </>
                                }
                                </Box>
                            </Box>
                </Box>
                <Box className="blog-container">
                    {selectedBlog ?
                        (
                            <Box>
                                <Box style={{ display: "flex", alignItems: "center", marginBottom:"1.32vw" }}>
                                    <Button>
                                        <img className="share-btn-icon-article" src={shareBtnArticle}></img>
                                        <Typography className="btn-share-readmore-container">Share</Typography>
                                    </Button>
                                </Box>
                                <Typography className="typo-heading-blog" gutterBottom>{selectedBlog?.title}</Typography>
                                <Typography className="typo-description-blog-selected" gutterBottom>
                                    <div  dangerouslySetInnerHTML={{ __html: selectedBlog?.description }} />
                                </Typography>
                                <Typography className="typo-content-blog" gutterBottom>{selectedBlog?.content}</Typography>
                            </Box>   
                        ) : (
                            <Box className="blogs-wrapper">
                                <Grid container spacing={4} className="blog-container-2">
                                    {this.state.travelArticleData?.map((article: any) => (
                                        <Grid item md={6} xs={6} className="blog-item-img-container" key={article.id}>
                                            <CardMedia
                                                component="img"
                                                alt={article.title}
                                                image={article.blog_image?.url}
                                                className="blog-card-img"
                                            />
                                            <Box className="blog-img-content">
                                                <Box className="blog-content-1">
                                                    <Typography className="blog-content-font blog-category-container-2">{article.category}</Typography>
                                                </Box>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <img className="share-btn-icon-blog" src={shareBtnBlog}></img>
                                                    <Typography className="blog-content-font blog-share-container-2">Share</Typography>
                                                </Box>
                                            </Box>
                                            <CardContent className="blog-card-content-container">
                                                <Typography gutterBottom className="typo-heading-blog">
                                                    {article.title}
                                                </Typography>
                                                <Typography gutterBottom className="typo-description-blog">
                                                    {article?.description?.substring(3, 111) || ''}
                                                </Typography>
                                                <Box className="blog-content">
                                                    <Box className="blog-content-1">
                                                        <Typography className="blog-content-font blog-author-container-2" gutterBottom>{article.author_name}</Typography>
                                                        <Typography className="blog-content-font blog-date-container-2" gutterBottom>{article.date}</Typography>
                                                    </Box>
                                                    <Typography className="blog-content-font blog-read-more-container-2" data-test-id="articleBlogBtn" gutterBottom onClick={() => this.handleReadMore(article)}>READ MORE</Typography>
                                                </Box>
                                            </CardContent>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )
                    }
                    <Box className="blog-container-3">
                        <Box className="blog-search">
                            <IconButton data-test-id="searchBtnBlog" type="button" aria-label="search" className="icon-btn" onClick={this.getSearchData}>
                                <img src={searchIcon}></img>
                            </IconButton>
                            <InputBase
                                placeholder="Search"
                                data-test-id="searchBlog"
                                className="search-placeholder"
                                inputProps={{ 'aria-label': 'Search' }}
                                value={this.state.searchChange}
                                onChange={this.handleSearchChange}
                            />
                        </Box>
                        <Box>
                            <Typography className="blog-container-3-heading">CATEGORIES</Typography>
                        </Box>
                        <Box className="blog-container-3-categories">
                            {
                                this.state.categoryList.map((article: any) => (
                                    <Button data-test-id="categorySet" className="category-list-btn" onClick={() => this.setCategory(article)}>
                                        <Typography 
                                        className={`blog-container-3-category ${this.state.categoryChange?.includes(article) ? 'selected-category' : ''}`}
                                        >{article}</Typography>
                                    </Button>
                                 ) )
                            }
                        </Box>
                        <Box >
                            <Typography className="blog-container-3-heading" >CITIES</Typography>
                        </Box>
                        <Box className="blog-container-3-cities">
                            {
                                this.state.citiesList.map((article: any) => (
                                    <Button data-test-id="citySet" className="category-list-btn" onClick={() => this.setCity(article)}>
                                        <Typography
                                        className={`blog-container-3-category ${this.state.cityChange?.includes(article) ? 'selected-category' : ''}`} >{article}</Typography>
                                     </Button>
                                 ) )
                            }
                        </Box>
                    </Box>
                </Box>
                <NavigationMenu {...this.props} />
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapperBackground: {
        backgroundRepeat: "no-repeat",
        backgroundSize : "cover",
        marginTop:"5.625vw",
        height:"43.194vw",
    },
}
// Customizable Area End
