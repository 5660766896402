import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  IconButton,
  InputBase,
  MenuItem,
  Grid,
  Switch,
  FormControl,
  Select
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import {
  searchIcon,
  addIcon,
  baileyImg,
  barImg,
  unLikeStar,
  likeStar,
} from "./assets";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import "./AdvancedSearch.css"
import { Snackbar, Alert} from "@mui/material";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
  
    const getImportantFeatures = () => {
      return (
        <Box className="mainIconContainer">
          {this.state.filterIcons.map((feature) => (
            <Box key={feature.name} className={this.state.important_categories.includes(feature.name) ? "icon-container-business" : "icon-container-business opacity-inactive"} onClick={() => this.handleIconClick(feature.name)} >
              <img
                src={feature.icon.url}
                alt={feature.name}
                className="icon-img-customisable"
                style={{ display: 'block', margin: '0 auto' }}
              />
              <span className="fitericon-span">{feature.name}</span>
            </Box>
          ))}
        </Box>
      );
    };
    
    const mapStyles = {
      height: '720px',
      width: '100%',
    };

    const defaultCenter = {
      lat: 37.09024,
      lng: -95.712891,
    };

    const renderTabs = () => (
      <Box className="tab-view-container">
        <Tabs
          aria-label="icon tabs example"
          className="tabs-container"
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          onChange={this.handleChangeActive}
          value={this.state.activeTab}
        >
          <Tab
            className={this.state.activeTab === 0 ? "selected-tab-list view-tab" : "list-view-tab view-tab"}
            data-test-id="mapViewTab"
            label="List View"
            onClick={this.getAllList}
          />
          <Tab
            className={this.state.activeTab === 1 ? "selected-tab-map view-tab" : "map-view-tab view-tab"}
            data-test-id="listViewTab"
            label="Map View"
            onClick={this.getAllList}
          />
        </Tabs>
      </Box>
    );

    const renderLocationToggle = () => (
      <Box className="location-container">
        <Typography className="location-text">Location: </Typography>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <Typography className="location-on-off-text">OFF</Typography>
            </Grid>
            <Grid item>
              <Switch
                className="switch"
                data-test-id="locationChange"
                checked={this.state.isLocation}
                name="isLocation"
                onChange={this.handleLocationChange}
                size="small"
              />
            </Grid>
            <Grid item>
              <Typography className="location-on-off-text">ON</Typography>
            </Grid>
          </Grid>
        </Typography>
      </Box>
    );

    return (
      <ThemeProvider theme={theme}>
        {/* Customizable Area Start */}
        <Box style={{ overflowX: "hidden" }}>
          <Header {...this.props} />
          <Box className="mainContainer">
            <Snackbar open={this.state.loginSnackbar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert
                severity="error"
                variant="filled"
                className="alert-submission-login"
                sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
              >
                Please Login!
              </Alert>
            </Snackbar>
            <Box className="typography-container">
              <Typography className="typo-style1">Start your search here</Typography>
            </Box>
            {renderTabs()}
            {renderLocationToggle()}
            <Box className="bar-list-container">
              <Box className="city-search">
                <IconButton data-test-id="searchBtnCity" type="button" aria-label="search" className="icon-btn" onClick={this.getAdvancedSearchList}>
                  <img className="search-icon-advance" src={searchIcon}></img>
                </IconButton>
                <InputBase
                  placeholder="City/State"
                  data-test-id="cityChanged"
                  color="primary"
                  className="city-state-placeholder"
                  value={this.state.search}
                  inputProps={{ 'aria-label': 'City/State' }}
                  onChange={this.handleSearchChangeCity}
                />
              </Box>
              <Box className="tab-segment">
                <Tabs
                  aria-label="icon tabs example"
                  className="tabs-segment-container"
                  value={this.state.tabValue}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "transparent"
                    }
                  }}
                  onChange={this.handleChange}
                >
                  <Tab className="tab-segment-all" value="all" label="All" />
                  {this.state.categories.map((category) => (
                    <Tab key={category} className="tab-segment-all" value={category} label={category} />
                  ))}
                </Tabs>
                <Box className="bar-dropdown">
                  <FormControl >
                    <Select
                      value={this.state.subCategory}
                      className="arrowBtn"
                      onChange={this.handleSubCategoryChange}
                      inputProps={{ 'aria-label': 'Without label' }}
                      displayEmpty
                      disableUnderline
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },

                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        Bar Type
                      </MenuItem>
                      {this.state.barType.map((type) => (
                        <MenuItem key={type} value={type} className="menuitem-list">
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box className="location-search">
                <IconButton data-test-id="searchBtn" type="button" aria-label="search" className="icon-btn" onClick={this.getAdvancedSearchList}>
                  <img className="search-icon-advance" src={searchIcon}></img>
                </IconButton>
                <InputBase
                  placeholder="Request a location"
                  className="location-placeholder"
                  data-test-id="searchChange"
                  inputProps={{ 'aria-label': 'Request a location' }}
                  value={this.state.changeLocation}
                  onChange={this.handleSearchChangeLocation}
                />
                <IconButton type="button" aria-label="search" className="icon-btn">
                  <img className="add-icon-advance" src={addIcon}></img>
                </IconButton>
              </Box>
            </Box>
            {getImportantFeatures()}
            <Box className="list-map-Container">
              {this.state.activeTab === 0 && (
                <Box style={{display:"flex", rowGap:"1.667vw", flexWrap:"wrap", justifyContent:"space-between"}}>
                  {this.state.bars?.map((barInfo:any, index:number) => (
                      <Box style={{width:"25.417vw"}}>
                        <Box className="img-header-footer">
                          <Box className="header-1">
                            <img src={barImg} className="bar-img-business" alt={`Bar ${index + 1}`} />
                            <Typography className="business-name-typo-advance">{barInfo.business_name}</Typography>
                          </Box>
                          <Button data-test-id="navigateBtn" onClick={()=> this.goToCatalogue(barInfo.id)} className="view-btn">View</Button>
                        </Box>
                        <div className="image-container">
                          <img
                            className="group-img-list"
                            src={barInfo.images[this.state.activeImageIndex[barInfo.id]] && barInfo.images[this.state.activeImageIndex[barInfo.id] || 0].url}
                            alt={`Group ${this.state.activeImageIndex[barInfo.id] + 1}`}
                          />
                        <div className="circles-container">
                          {Array.isArray(barInfo.images) ? barInfo.images.slice(0, 4).map((image: string, index: number) => (
                            <button
                              key={index}
                              data-test-id="CircleClickBtn"
                              className={`circle ${this.state.activeImageIndex[barInfo.id] === index ? 'active' : ''}`}
                              onClick={() => this.handleCircleClick(barInfo.id, index)}
                            ></button>
                          )) : null}
                        </div>
                        <div className="star-img-position">
                            <Button className="btn-advanced" data-test-id="LikeChangeBtn" onClick={this.state.loginToken ? () => this.handleLikeChange(barInfo.id) : this.pleaseLoginPopup}>
                              <img className="start-img" alt="Star" src={this.state.likedBusinesses[barInfo.id] ? likeStar : unLikeStar} />
                            </Button>
                          </div>
                        </div>
                        <Box className="img-header-footer">
                          <Typography className="business-details-typo-advance" >Check-Ins {barInfo.total_check_ins}</Typography>
                          {barInfo.distance && <Typography className="business-details-typo-advance-distance" data-test-id="barDistance" >{barInfo.distance}</Typography>}
                        </Box>
                      </Box>
                  ))}
                </Box>
              )}
              {this.state.activeTab === 1 && (
                <div style={{ width: '100%' }}>
                  {this.state.bars && this.state.bars.length > 0 && (
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={5}
                      center={defaultCenter}
                    >
                      {this.state.bars.map((element:any, index:number) => {
                        // lat and lng are coming opposite from backend
                        const iconUrl = this.getCategoryIconUrl(element.help_us_help_you?.catgory_name);
                        return (
                          <Marker
                            icon={{
                              url: iconUrl
                            }}
                            data-test-id="mapMarker"
                            key={index}
                            position={{
                              lat: element.longitude,
                              lng: element.latitude
                            }}
                            title={element.business_name}
                            onClick={() => {
                              this.handleMarkerClick(element);
                            }}
                          >
                            {this.state.selectedBar && this.state.selectedBar.id === element.id &&  (
                            <InfoWindow
                              data-test-id="infoWindow"
                              position={{
                                lat: this.state.selectedBar.longitude,
                                lng: this.state.selectedBar.latitude,
                              }}
                              onCloseClick={ this.setSelectedElement }
                            >
                                <Box >
                                  <Box className="img-header-footer">
                                    <Box className="header-1">
                                      <img src={barImg} className="bar-img-business" />
                                      <Typography className="business-name-typo-advance">{this.state.selectedBar.business_name}</Typography>
                                    </Box>
                                    <Button data-test-id="categoryBtn2" onClick={() => this.goToCatalogue(element.id)} className="view-btn">View</Button>
                                  </Box>
                                  <div className="image-container-1">
                                    <img
                                      className="group-img-list-1"
                                      src={element.images[this.state.activeImageIndex[element.id]] && element.images[this.state.activeImageIndex[element.id] || 0].url}
                                      alt={`Group ${this.state.activeImageIndex[element.id] + 1}`}
                                    />
                                    <div className="circles-container">
                                      {Array.isArray(element.images) ? element.images.slice(0, 4).map((image: string, index: number) => (
                                        <button
                                          key={index}
                                          data-test-id="CircleClickBtn2"
                                          className={`circle ${this.state.activeImageIndex[element.id] === index ? 'active' : ''}`}
                                          onClick={() => this.handleCircleClick(element.id, index)}
                                        ></button>
                                      )) : null}
                                    </div>
                                    <div className="star-img-position">
                                      <Button className="btn-advanced-1" data-test-id="LikeChangeBtn2" onClick={this.state.loginToken ? () => this.handleLikeChange(element.id) : this.pleaseLoginPopup}>
                                        <img className="start-img" alt="Star" src={this.state.likedBusinesses[element.id] ? likeStar : unLikeStar} />
                                      </Button>
                                    </div>
                                  </div>
                                  <Box className="img-header-footer">
                                    <Typography className="business-details-typo-advance">Check-Ins {element.total_check_ins}</Typography>
                                    {this.state.selectedBar.distance && <Typography className="business-details-typo-advance" data-test-id="barDistance">{this.state.selectedBar.distance}</Typography>}
                                  </Box>
                                </Box>
                            </InfoWindow>
                            )}
                          </Marker>
                        );
                      })}
                    </GoogleMap>
                  )}
                </div>
              )}
            </Box>
          </Box>
          <NavigationMenu {...this.props} />
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End