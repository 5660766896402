import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Testimonial {
  id: number;
  header: string;
  description: string;
  author_name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  testimonialData: Testimonial[];
  currentTestimonialIndex:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      testimonialData:[],
      currentTestimonialIndex: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.testimonialApiCallId !== "" &&
      this.testimonialApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && responseJson.data){
        this.setState({testimonialData:responseJson.data})
      }
      runEngine.debugLog("API Message Received", message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  testimonialApiCallId: any;
  async componentDidMount() {
    super.componentDidMount();
    this.getTestimonials();
  }

  handlePrevTestomonial = () => {
    this.setState((prevState) => ({
      currentTestimonialIndex: prevState.currentTestimonialIndex === 0 ? prevState.testimonialData.length - 1 : prevState.currentTestimonialIndex - 1
    }));
  }

  handleNextTestimonial = () => {
    this.setState((prevState) => ({
      currentTestimonialIndex: (prevState.currentTestimonialIndex + 1) % prevState.testimonialData.length
    }));
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditions"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  goToLandingPage = () => {
    this.props.navigation.navigate("Contactus");
  };

  goToSignup = () => {
    this.props.navigation.navigate("BusinessSignupForm");
  };

  getTestimonials = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.testimonialApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestimonialApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
